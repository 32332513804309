body{
    font-size: 13px;
  }
  .data_table{
    font-size: '10%' !important;
  }
  .box{
    min-height: 33px;
    border: 2px solid #000;
    padding: 5px;
  }
  .box_check{
    height: 33px;
    width: 33px;
    border: 2px solid #000;
  }
  .box_bottom{
    height: 33px;
    border-bottom: 1px solid #000;

  }
  .quote_number{
    min-height: 10rem !important;
    text-align: center;
  }
  .section_l{
    border: 1px solid #000;
    padding: 10px;
  }
  h2{
    font-size: 26px;
    font-weight: 800;
  }
  h3{
    font-size: 18px;
    font-weight: 800;
  }
  h4{
    font-size: 16px;
    font-weight: 800;
  }
  .logo{
    max-height: 100px;
    margin-bottom: 10px;
  }
  .logo img{
    max-height: 100px;
    margin-bottom: 10px;
  }
  .fs-smm{
    font-size: 11px;
  }
  .fs-sm{
    font-size: 9px;
  }
  .check {
    height: 34px;
    border-bottom: 5px solid #212529;
    border-right: 5px solid #212529;
    transform: rotate(45deg);
    margin: -6px 5px;
}